import { createReducer, on } from '@ngrx/store';
import * as TasksTabActions from '../actions/tasks.actions';

export interface TasksStoreInterface {
  view: string | null;
  prevTab: string | null;
  currentTab: string | null;
}

export const initialState: TasksStoreInterface = {
  view: null,
  prevTab: null,
  currentTab: null,
};

export const tasksTabReducer = createReducer(
  initialState,
  on(TasksTabActions.setTasksView, (state, { view }) => ({
    ...state,
    view,
  })),
  on(TasksTabActions.setTasksTab, (state, { newTab }) => ({
    ...state,
    prevTab: state.currentTab,
    currentTab: newTab,
  })),
);
